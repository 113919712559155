import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ref, get, set } from "firebase/database";
import { db, auth } from "../firebase/config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { jsPDF } from "jspdf";
import { PaystackButton } from "react-paystack";
import "../styles/Raffle.css";

// Notification function
const notify = (type, message) => {
  switch (type) {
    case "success":
      toast.success(message);
      break;
    case "info":
      toast.info(message);
      break;
    case "warning":
      toast.warn(message);
      break;
    case "error":
      toast.error(message);
      break;
    default:
      toast(message);
  }
};

const Raffle = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [state, setState] = useState("");
  const [isPaid, setIsPaid] = useState(false);
  const [ticketNumber, setTicketNumber] = useState("");
  const navigate = useNavigate();

  const publicKey = "YOUR_PAYSTACK_PUBLIC_KEY"; // Replace with your Paystack public key
  const amount = 100000; // Amount in kobo (₦1000 = 100000 kobo)
  const currency = "NGN";

  // Fetch user details from Firebase Realtime Database
  useEffect(() => {
    const fetchUserDetails = async () => {
      if (auth.currentUser) {
        const userId = auth.currentUser.uid;
        const userRef = ref(db, `users/${userId}`);

        try {
          const snapshot = await get(userRef);
          if (snapshot.exists()) {
            const userData = snapshot.val();
            setName(userData.name || "");
            setEmail(userData.email || "");
            setPhone(userData.phone || "");
            setAddress(userData.address || "");
            setState(userData.state || "");
          }
        } catch (error) {
          console.error("Error fetching user details:", error);
        }
      }
    };

    fetchUserDetails();
  }, []);

  const handlePaymentSuccess = async (response) => {
    setIsPaid(true);
    notify("success", "Payment successful!");
    const ticket = generateTicket();
    saveDetailsToDatabase(ticket);
    generatePDF(ticket);
  };

  const handlePaymentClose = () => {
    notify("info", "Payment process closed.");
  };

  const generateTicket = () => {
    const ticket = `RAFFLE-${Math.floor(100000 + Math.random() * 900000)}`;
    setTicketNumber(ticket);
    return ticket;
  };

  const saveDetailsToDatabase = async (ticket) => {
    if (auth.currentUser) {
      const userId = auth.currentUser.uid;
      const userRef = ref(db, `users/${userId}`);

      const userData = {
        name,
        email,
        phone,
        address,
        state,
        ticketNumber: ticket,
        paid: true,
      };

      try {
        await set(userRef, userData);
        notify("success", "Your details and ticket have been saved!");
      } catch (error) {
        console.error("Error saving to database:", error);
        notify("error", "Failed to save details. Please try again.");
      }
    }
  };

  const generatePDF = (ticket) => {
    const doc = new jsPDF();
    doc.setFont("helvetica", "normal");
    doc.setFontSize(24);
    doc.text(`Ticket Number: ${ticket}`, 105, 20, null, null, "center");
    doc.setFontSize(16);
    doc.text("My Raffle Ticket", 105, 30, null, null, "center");
    doc.setFontSize(14);
    doc.text(`Name: ${name}`, 20, 50);
    doc.text(`Email: ${email}`, 20, 60);
    doc.text(`Phone: ${phone}`, 20, 70);
    doc.text(`Address: ${address}`, 20, 80);
    doc.text(`State: ${state}`, 20, 90);
    doc.text(`Payment Status: Paid`, 20, 100);
    doc.text(`Amount Paid: ₦1000`, 20, 110);
    doc.save(`Raffle_Ticket_${ticket}.pdf`);
  };

  const componentProps = {
    email,
    amount,
    publicKey,
    text: "Pay ₦1000 Now",
    onSuccess: handlePaymentSuccess,
    onClose: handlePaymentClose,
    currency,
  };

  return (
    <div className="raffle-container">
      <div className="raffle-header">
        <h1>Win Big with Our Raffle!</h1>
        <p>Pay just ₦1000 for a chance to win amazing prizes!</p>
      </div>

      <div className="raffle-form">
        <h2>Join the Raffle Now</h2>
        <p>Complete your details and secure your ticket.</p>
        <input
          type="text"
          placeholder="Full Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          className="input-field"
        />
        <input
          type="email"
          placeholder="Email Address"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="input-field"
        />
        <input
          type="text"
          placeholder="Phone Number"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          className="input-field"
        />
        <input
          type="text"
          placeholder="Address"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          className="input-field"
        />
        <input
          type="text"
          placeholder="State"
          value={state}
          onChange={(e) => setState(e.target.value)}
          className="input-field"
        />

        {!isPaid && <PaystackButton className="pay-button" {...componentProps} />}
      </div>

      {isPaid && ticketNumber && (
        <div className="ticket-section">
          <h2 className="ticket-number">🎟️ Ticket Number: {ticketNumber}</h2>
          <h3 className="ticket-title">My Raffle Ticket</h3>
          <p>We have sent your ticket to your email in PDF format. Check your downloads!</p>
        </div>
      )}

      <ToastContainer />
    </div>
  );
};

export default Raffle;
